// extracted by mini-css-extract-plugin
export var content = "FeatureSection-module--content--14be0";
export var description = "FeatureSection-module--description--21c96";
export var featureSection = "FeatureSection-module--featureSection--268b5";
export var featureSectionInner = "FeatureSection-module--featureSectionInner--d82fd";
export var featureSectionPrimary = "FeatureSection-module--featureSectionPrimary--6ed79";
export var featureSectionSecondary = "FeatureSection-module--featureSectionSecondary--7b11e";
export var grid = "FeatureSection-module--grid--6dfb2";
export var head = "FeatureSection-module--head--df881";
export var headDescription = "FeatureSection-module--headDescription--5a609";
export var headSubtext = "FeatureSection-module--headSubtext--bfbbd";
export var headText = "FeatureSection-module--headText--b9458";
export var image = "FeatureSection-module--image--b3e27";
export var item = "FeatureSection-module--item--ddd77";
export var title = "FeatureSection-module--title--4666e";