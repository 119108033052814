// src/components/MenuSectionGridThree/MenuSectionGridThree.tsx

import React from 'react';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';
import Button from '../Button/Button';
import FormattedText from '../commons/FormattedText/FormattedText';
import * as styles from './FeatureSection.module.css';

type ColorType = 'primary' | 'secondary';

type Item = {
  imageSrc: string;
  imageAlt: string;
  title: string;
  price: string;
  description: string;
};

type FeatureSectionProps = {
  title: string;
  colorType: ColorType;
  items: Item[];
  sectionId: string;
  subtitle: string;
  ctrLink: string;
  ctrText: string;
  description: string;
};

const MenuSectionGridThree: React.FC<FeatureSectionProps> = ({
  title,
  colorType,
  items,
  sectionId,
  subtitle,
  description,
  ctrLink,
  ctrText,
}) => {
  let featureSectionClass = styles.featureSectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    featureSectionClass = styles.featureSectionPrimary;
  } else if (colorType === 'secondary') {
    featureSectionClass = styles.featureSectionSecondary;
  }
  return (
    <ScrollAnimation>
      <section className={featureSectionClass} id={sectionId}>
        <div className={styles.featureSectionInner}>
          <h2 className={styles.head}>
            <span className={styles.headText}>{title}</span>
            {subtitle && <span className={styles.headSubtext}>{subtitle}</span>}
          </h2>
          {description && (
            <p className={styles.headDescription}>
              <FormattedText text={description} />
            </p>
          )}
          <div className={styles.grid}>
            {items.map((item, index) => (
              <div key={index} className={styles.item}>
                <img
                  className={styles.image}
                  src={item.imageSrc}
                  alt={item.imageAlt}
                />
                <div className={styles.content}>
                  <h3 className={styles.title}>{item.title}</h3>
                  {item.price && <p className={styles.price}>{item.price}</p>}
                  {item.description && (
                    <p className={styles.description}>
                      <FormattedText text={item.description} />
                    </p>
                  )}
                  {ctrLink && <Button text={ctrText} ctaLink={ctrLink} />}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default MenuSectionGridThree;
