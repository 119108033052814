import * as React from 'react';
import MenuSectionGridThree from '../components/MenuSectionGridThree/MenuSectionGridThree';
import Slideshow from '../components/Slideshow/Slideshow';
import Layout from '../components/layout';
import Seo from '../components/seo';

export const Head = () => {
  return (
    <Seo
      title="お弁当"
      description="肉のながおかのお弁当をご紹介いたします。カルビ弁当、1,500円等。"
    />
  );
};

const BentoPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <Slideshow
          title="Bento"
          subtitle="お弁当"
          images={[
            { id: 2, src: '/nagaoka2023/image/top2.png', alt: 'Image 2' },
            { id: 3, src: '/nagaoka2024/t101.jpg', alt: 'Image 3' },
            { id: 1, src: '/nagaoka2024/t104.jpg', alt: 'Image 1' },
          ]}
        />
        <MenuSectionGridThree
          title="お弁当"
          subtitle="BENTO"
          items={[
            {
              id: 1,
              title: 'ヒレステーキ弁当',
              price: '¥4,200',
              imageSrc: '/nagaoka2024/menu/m105.JPG',
              imageAlt: 'ヒレステーキ弁当',
            },
            {
              id: 2,
              title: 'ステーキ弁当',
              price: '¥2,400',
              imageSrc: '/nagaoka2024/menu/ステーキ弁当.jpg',
              imageAlt: 'ステーキ弁当',
            },
            {
              id: 3,
              title: '牛ハラミ弁当',
              price: '¥2,200',
              imageSrc: '/nagaoka2024/menu/m105.JPG',
              imageAlt: '牛ハラミ弁当',
            },
            {
              id: 3,
              title: '牛タン塩弁当',
              price: '¥2,800',
              imageSrc: '/comingsoon.jpg',
              imageAlt: '牛タン塩弁当',
            },
            {
              id: 4,
              title: 'ステーキ&カルビ弁当',
              price: '¥2,000',
              imageSrc: '/comingsoon.jpg',
              imageAlt: 'ステーキ&カルビ弁当',
            },
            {
              id: 5,
              title: '上カルビ弁当',
              price: '¥1,800',
              imageSrc: '/nagaoka2024/menu/上カルビ弁当.jpg',
              imageAlt: '上カルビ弁当',
            },
            {
              id: 6,
              title: 'ねぎカルビ弁当',
              price: '¥1,600',
              imageSrc: '/nagaoka2024/menu/m103.JPG',
              imageAlt: 'ねぎカルビ弁当',
            },
            {
              id: 7,
              title: 'カルビ弁当',
              price: '¥1,500',
              imageSrc: '/nagaoka2024/menu/m102.JPG',
              imageAlt: 'カルビ弁当',
            },
            {
              id: 9,
              title: '牛すき焼き弁当',
              price: '¥1,800',
              imageSrc: '/nagaoka2024/menu/牛すき焼き弁当.jpg',
              imageAlt: '牛すき焼き弁当',
            },
            {
              id: 9,
              title: 'ロース弁当',
              price: '¥1,800',
              imageSrc: '/comingsoon.jpg',
              imageAlt: 'ロース弁当',
            },
          ]}
        />
      </Layout>
    </div>
  );
};

export default BentoPage;
